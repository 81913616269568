<template>
  <div>
    <nuxt />
  </div>
</template>

<script>
export default {
  head() {
    return {
      // bodyAttrs: {
      //   class: 'has-navbar-fixed-top'
      // },
      meta: [
        {
          hid: 'og:url',
          property: 'og:url',
          content: `${process.env.HTTP_DOMAIN}${this.$route.path}`
        }
      ]
    }
  }
}
</script>
